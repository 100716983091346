import PresaleContractABI from './PresaleContract.json';
import BNB_ABI from './BNB_ABI.json';

// export const presaleContract = {
//     netId: 97,
//     network: 'testnet',
//     // address: '0xdeB630f0869AB3399f0082e12fbD16a3Cb5C0994',
//     address: '0x71FF877FAbEA3183200fb7918e94353Ea7E132E1',
//     abi: PresaleContractABI
// }

export const presaleContract = {
    netId: 56,
    network: 'bscscan',
    address: '0x94f7cD4e8e4D1afF8d7449A73D8dEe79F2Cbcf18',
    abi: PresaleContractABI
}

export const CONTRACT_FOR_BNB = {
    address: '0x58f876857a02d6762e0101bb5c46a8c1ed44dc16',
    abi: BNB_ABI
}